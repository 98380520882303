import './footer.css'

let Footer = () => {
    return (
        <div id="footer">
            © 2021 WESCOA USA | Inc All Rights Reserved | Website Design by Elliot Clark
        </div>
    )
}

export default Footer